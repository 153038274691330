import React from "react"
import styled from "styled-components"
import DiscordIco from "../images/discord-icon.svg"
import FacebookIco from "../images/facebook-icon.svg"
import InstagramIco from "../images/instagram-icon.svg"
import KoFiIcon from "../images/ko-fi-icon.svg"
import TwitterIco from "../images/twitter-icon.svg"
import PatreonIcon from "../images/patreon-icon.svg"
import useSocialLinks from "../utils/useSocialLinks"

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
`

const Icon = styled.a`
  padding: 8px;

  &:nth-child(6) {
    padding-right: 0;
  }
`

const SocialsBar = () => {
  const {
    strapiInstagram,
    strapiFacebook,
    strapiDiscord,
    strapiKofi,
    strapiTwitter,
    strapiPatreon,
  } = useSocialLinks()

  return (
    <Wrapper>
      <div>
        <Icon
          href={strapiFacebook.facebook}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <img src={FacebookIco} alt="facebook icon" title="facebook" />
        </Icon>
        <Icon
          href={strapiTwitter.twitter}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <img src={TwitterIco} alt="twitter icon" title="twitter" />
        </Icon>
        <Icon
          href={strapiInstagram.instagram}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <img src={InstagramIco} alt="instagram icon" title="instagram" />
        </Icon>
        <Icon
          href={strapiDiscord.discord}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <img src={DiscordIco} alt="discord icon" title="discord" />
        </Icon>
        <Icon
          href={strapiPatreon.patreon}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <img src={PatreonIcon} alt="patreon icon" title="patreon" />
        </Icon>
        <Icon
          href={strapiKofi.kofi}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <img src={KoFiIcon} alt="ko-fi icon" title="ko-fi" />
        </Icon>
      </div>
    </Wrapper>
  )
}

export default SocialsBar
