import styled from "styled-components"

const Logo = styled.div`
  text-transform: uppercase;
  margin-bottom: 0;

  .text-logo {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
    font-family: "Oswald";
    font-size: 26px;
    display: none;
  }

  img {
    margin-bottom: 0 !important;
  }

  @media (max-width: 502px) {
    .text-logo {
      display: unset;
    }
    .img-logo {
      display: none;
    }
  }
`

export default Logo
