import React, { useState, useEffect } from "react"
import { Link as GatsbyLink, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { connect } from "react-redux"
import MenuIco from "../images/menu.svg"
import SocialsBar from "./socialsBar"
import CurrencyDropdown from "./styled/currencyDropdown"
import Logo from "./styled/logo"
import { Container } from "./styled/container"
import Img from "gatsby-image"

const Navbar = styled.header`
  padding: 32px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 980px) {
    padding: 32px 0 8px 0;
  }
`

const MenuWrapper = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 979px) {
    display: none;
  }
`

const Link = styled(GatsbyLink)`
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  padding: 12px;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.5;
  transition: 0.2s;

  &:nth-child(6) {
    padding-right: 24px;
  }

  :hover {
    opacity: 1;
  }
`

const MenuMobileIco = styled.img`
  cursor: pointer;
  margin-left: 16px;
  margin-bottom: 0;
`

const MobileMenu = styled.div`
  display: flex;
  @media (min-width: 980px) {
    display: none;
  }
`

const MobileMenuWrapper = styled.nav`
  overflow: hidden;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
  height: ${props => (props.open ? "240px" : 0)};
  border-bottom: 1px solid ${props => props.theme.colors.lightBorder};

  a {
    display: block;
    margin-bottom: 16px;
    transition: all 0.4s ease-in-out;
    padding: 0;
    padding-right: 0;

    :nth-child(6) {
      padding-right: 0;
    }
  }

  @media (min-width: 980px) {
    display: none;
  }
`

const activeStyles = {
  opacity: 1,
}

const Header = ({ siteTitle, toggleCurrency, currency }) => {
  const options = ["$USD", "€EUR"]
  const [isOpen, toggle] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logoV2.png" }) {
        childImageSharp {
          fixed(width: 292, height: 69) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  useEffect(() => {
    const setCurrency = () => {
      const storedCurrency = localStorage.getItem("currency")

      if (storedCurrency === "false" && currency === false) {
        return toggleCurrency()
      }
    }

    setCurrency()
  }, [])

  return (
    <Container>
      <SocialsBar />
      <Navbar>
        <Logo>
          <div className="img-logo">
            <GatsbyLink to="/">
              <Img fixed={data.file.childImageSharp.fixed} />
            </GatsbyLink>
          </div>
          <GatsbyLink to="/" className="text-logo">
            {siteTitle}
          </GatsbyLink>
        </Logo>
        <MenuWrapper>
          <Link to="/news" activeStyle={activeStyles} partiallyActive={true}>
            News
          </Link>
          <Link
            to="/articles"
            activeStyle={activeStyles}
            partiallyActive={true}
          >
            Articles
          </Link>
          <Link
            to="/streamers"
            activeStyle={activeStyles}
            partiallyActive={true}
          >
            Streamers
          </Link>
          <Link to="/partners" activeStyle={activeStyles}>
            Partners
          </Link>
          <Link to="/support-us" activeStyle={activeStyles}>
            Support Us
          </Link>
          <Link to="/faq" activeStyle={activeStyles}>
            FAQ
          </Link>
          <CurrencyDropdown
            options={options}
            onChange={() => {
              toggleCurrency()
              localStorage.setItem("currency", currency)
            }}
            value={currency ? options[1] : options[0]}
            placeholder="Select an option"
          />
        </MenuWrapper>
        <MobileMenu>
          <CurrencyDropdown
            options={options}
            onChange={() => {
              toggleCurrency()
              localStorage.setItem("currency", currency)
            }}
            value={currency ? options[1] : options[0]}
            placeholder="Select an option"
          />
          <MenuMobileIco
            src={MenuIco}
            alt="mobile menu icon"
            onClick={e => toggle(!isOpen)}
          />
        </MobileMenu>
      </Navbar>
      <MobileMenuWrapper open={isOpen}>
        <Link to="/news" activeStyle={activeStyles} partiallyActive={true}>
          News
        </Link>
        <Link to="/articles" activeStyle={activeStyles} partiallyActive={true}>
          Articles
        </Link>
        <Link to="/streamers" activeStyle={activeStyles} partiallyActive={true}>
          Streamers
        </Link>
        <Link to="/partners" activeStyle={activeStyles}>
          Partners
        </Link>
        <Link to="/support-us" activeStyle={activeStyles}>
          Support Us
        </Link>
        <Link to="/faq" activeStyle={activeStyles}>
          FAQ
        </Link>
      </MobileMenuWrapper>
    </Container>
  )
}

const mapStateToProps = state => ({
  currency: state.currency,
})

const mapDispatchToProps = dispatch => {
  return {
    toggleCurrency: () => dispatch({ type: "TOGGLE_CURRENCY" }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
