import React from "react"
import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"
import moment from "moment"
import { Container } from "./styled/container"

const FooterWrapper = styled.footer`
  border-top: 1px solid ${props => props.theme.colors.gameCardBg};
  margin-top: 24px;
`

const FooterContent = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  @media (max-width: 1050px) {
    flex-direction: column;
  }
`

const Copyrights = styled.small`
  line-height: 16px;
  color: ${props => props.theme.colors.white};

  .d-inline {
    font-size: 11px;
  }

  .d-block {
    display: block;
    margin-top: 4px;
  }
  @media (max-width: 1050px) {
    margin-top: 16px;
    order: 2;
    text-align: center;
  }
`

const Link = styled(GatsbyLink)`
  font-size: 14px;
  line-height: 16px;

  color: ${props => props.theme.colors.white};
  text-decoration: none;
  &:last-child {
    padding-right: 0px;
  }

  :nth-child(1) {
    padding-left: 0;
  }
`

const FooterLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 12px;
  justify-items: end;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`

const Footer = () => {
  const year = moment().format("YYYY")
  return (
    <FooterWrapper>
      <Container>
        <FooterContent>
          <Copyrights>
            <span className="d-inline">
              FGCharts uses{" "}
              <a
                href="https://store.steampowered.com/"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                Steam API
              </a>
              . Not affiliated with Valve. All trademarks are the property of
              their respective owners.
            </span>{" "}
            <span className="d-block">
              &copy;FGCharts 2020 - {year} | Contact at{" "}
              <a href="mailto:info@fgcharts.com "> info@fgcharts.com</a>
            </span>
          </Copyrights>
          <FooterLinks>
            <Link to="/news">News</Link>
            <Link to="/articles">Articles</Link>
            <Link to="/partners">Partners</Link>
            <Link to="/streamers">Streamers</Link>
            <Link to="/support-us">Support Us</Link>
            <Link to="/faq">FAQ</Link>
            <Link to="/terms">Privacy policy</Link>
          </FooterLinks>
        </FooterContent>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
