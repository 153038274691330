import styled from "styled-components"
import Dropdown from "react-dropdown"
import "react-dropdown/style.css"

const CurrencyDropdown = styled(Dropdown)`
  .Dropdown-control {
    background-color: transparent;
    color: ${props => props.theme.colors.white};
    border: ${props => `2px solid ${props.theme.colors.primary}`};
    border-radius: 0px;
    cursor: pointer;
  }

  .is-open .Dropdown-arrow {
    border-color: ${props =>
      `${props.theme.colors.white} transparent transparent`};
    border-width: 0px 5px 5px !important;
  }

  .Dropdown-arrow {
    border-color: ${props =>
      `${props.theme.colors.white} transparent transparent`};
    border-width: 5px 5px 0px !important;
  }
  .Dropdown-menu {
    border: ${props => `2px solid ${props.theme.colors.primary}`};
  }

  .Dropdown-option {
    background-color: ${props => props.theme.colors.gameCardBg};
    color: ${props => props.theme.colors.white};

    :hover {
      background-color: ${props => props.theme.colors.gameCardBgLight};
    }
  }

  .Dropdown-option.is-selected {
    display: none;
  }
`

export default CurrencyDropdown
