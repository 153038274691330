import styled from "styled-components"

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1152px;
  width: ${props => props.w && props.w};
  padding: 0 12px;
  display: ${props => (props.main ? "flex" : "")};
  min-height: ${props => (props.main ? "100vh" : "")};
  flex-direction: ${props => (props.main ? "column" : "")};
  min-width: ${props => props.loading && "1152px"};

  @media (max-width: 1364px) {
    min-width: unset;
  }
`
