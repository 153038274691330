import { useStaticQuery, graphql } from "gatsby"

const useSocialLinks = () => {
  const data = useStaticQuery(graphql`
    {
      strapiInstagram {
        instagram
      }
      strapiFacebook {
        facebook
      }
      strapiDiscord {
        discord
      }
      strapiKofi {
        kofi
      }
      strapiTwitter {
        twitter
      }
      strapiPatreon {
        patreon
      }
    }
  `)
  return data
}

export default useSocialLinks
