// Websit Theme

export default {
  colors: {
    primary: "#4960EA",
    white: "#FBFBFC",
    black: "#111021",
    blueDark: "#130E46",
    blueGray: "#3A3C62",
    blueGrayLight: "#ADB9D8",
    yellow: "#FFCD56",
    red: "#F84F68",
    green: "#8FED6E",
    orange: "#F19954",
    brown: "#47323B",
    gameCardBg: "#23222B",
    cardBgLight: "#F4F7FF",
    gameCardTitle: "#989898",
    gameCardBgLight: "#2B2A34",
    lightBorder: "#302D4F",
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  effects: {
    btnPrimaryShadow: "0px 3px 4px rgba(73, 96, 234, 0.15)",
    gameCardShadow: "0px 4px 4px rgba(35, 34, 43, 0.25)",
  },
}
