import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import Theme from "../styles/theme"
import GlobalStyles from "../styles/globalStyles"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <Header siteTitle={data.site.siteMetadata.title} />
        <main style={{ flexGrow: 1 }}>{children}</main>
        <Footer />
      </ThemeProvider>
    </div>
  )
}

export default Layout
